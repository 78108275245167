// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-ua-js": () => import("./../../src/pages/blog.ua.js" /* webpackChunkName: "component---src-pages-blog-ua-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-ua-js": () => import("./../../src/pages/contact.ua.js" /* webpackChunkName: "component---src-pages-contact-ua-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ua-js": () => import("./../../src/pages/index.ua.js" /* webpackChunkName: "component---src-pages-index-ua-js" */),
  "component---src-templates-blog-template-js": () => import("./../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-category-js": () => import("./../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-children-js": () => import("./../../src/templates/children.js" /* webpackChunkName: "component---src-templates-children-js" */),
  "component---src-templates-page-js": () => import("./../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

